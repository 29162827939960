<template>
  <div v-bind:style="{ height: appHeight + 'px' }" class="overflow-hidden">
    <router-view />
  </div>
  <!-- <cookie></cookie> -->
</template>

<script>

// import Cookie from '@/components/Cookie'

export default {
  name: 'App',  
  components: {
    // Cookie
  },
  data () {
    return {
      appHeight: 0
    }
  },  
  mounted () {
    window.addEventListener('resize', this.resizeWindow)
    this.resizeWindow()
  },
  methods: {
    resizeWindow() {
      this.appHeight = window.innerHeight
      console.log(window.innerHeight)
    }
  }
}

</script>
